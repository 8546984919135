import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/Login.css";
import Adminlogin from "./Components/Admin_Login";
import AdminDashboard from "./Components/Dashboard";
import ProductList from "./Components/ProductList";
import Category from './Components/Category';
import Subcategory from './Components/Subcategory';
import Forgotpassword from './Components/Forgotpassword';
import AddProduct from './Components/Addproduct';
import Colormanager from './Components/Colormanager';
import EditProduct from './Components/EditProduct';
import Dashboardcounts from './Components/Dashboardcounts';
import Adminprofile from './Components/Adminprofile';
import Comboproduct from './Components/Comboproduct';
import EditCombo from './Components/Editcombo';
import AddBanner from './Components/Addbanner';
import BannerList from './Components/Bannerlist';
import PrivateRoute from './Components/PrivateRoute';  // Import the PrivateRoute component
import '../src/assets/css/Global.css';  // Import global styles
import AddFitType from './Components/AddFitType';
import EditFitType from './Components/EditFitType';
import EditFabricType from './Components/EditFabricType';
import EditHSN from './Components/Edithsn';
import EditPattern from './Components/Editpattern';
import EditOccasion from './Components/Editoccasion';
import EditCollar from './Components/Editcollar';


function App() {
    return (
        <> <Router>
            <Routes>
                <Route path="/" element={<Adminlogin />} />
                <Route path="/AdminLogin" element={<Adminlogin />} />
                <Route
                    path="/AdminDashboard/*"
                    element={<PrivateRoute>
                        <AdminDashboard />
                    </PrivateRoute>} />
                <Route
                    path="/ProductList"
                    element={<PrivateRoute>
                        <ProductList />
                    </PrivateRoute>} />
                <Route
                    path="/Category"
                    element={<PrivateRoute>
                        <Category />
                    </PrivateRoute>} />
                <Route
                    path="/Comboproduct"
                    element={<PrivateRoute>
                        <Comboproduct />
                    </PrivateRoute>} />
                <Route
                    path="/Subcategory"
                    element={<PrivateRoute>
                        <Subcategory />
                    </PrivateRoute>} />
                <Route
                    path="/bannerlist"
                    element={<PrivateRoute>
                        <BannerList />
                    </PrivateRoute>} />
                <Route
                    path="/Subcategory/:categoryId"
                    element={<PrivateRoute>
                        <Subcategory />
                    </PrivateRoute>} />
                <Route
                    path="/adminprofile"
                    element={<PrivateRoute>
                        <Adminprofile />
                    </PrivateRoute>} />
                <Route path="/forgotpassword" element={<Forgotpassword />} />
                <Route
                    path="/AddProduct"
                    element={<PrivateRoute>
                        <AddProduct />
                    </PrivateRoute>} />
                <Route
                    path="/Colormanager"
                    element={<PrivateRoute>
                        <Colormanager />
                    </PrivateRoute>} />
                <Route
                    path="/EditProduct/:id"
                    element={<PrivateRoute>
                        <EditProduct />
                    </PrivateRoute>} />
                <Route
                    path="/AdminDashboard/dashboardcounts"
                    element={<PrivateRoute>
                        <Dashboardcounts />
                    </PrivateRoute>} />
                <Route
                    path="/editcombo/:id"
                    element={<PrivateRoute>
                        <EditCombo />
                    </PrivateRoute>} />
                <Route
                    path="/addbanner"
                    element={<PrivateRoute>
                        <AddBanner />
                    </PrivateRoute>} />
                <Route
                    path="/Editfabrictype/:fabricId"
                    element={<PrivateRoute>
                        <EditFabricType />
                    </PrivateRoute>} />

                <Route
                    path="/addfittype"
                    element={<PrivateRoute>
                        <AddFitType />
                    </PrivateRoute>} />
                <Route
                    path="/editfittype/:fitId"
                    element={<PrivateRoute><EditFitType /></PrivateRoute>}
                />

                <Route
                    path="/edithsn/:id"
                    element={<PrivateRoute>
                        <EditHSN />
                    </PrivateRoute>}
                />
                <Route
                path="/editpattern/:id"
                element={<PrivateRoute><EditPattern /></PrivateRoute>}
                />
                <Route
                path="/editoccasion/:id"
                element={<PrivateRoute>
                    <EditOccasion />
                </PrivateRoute>}
                />
                <Route 
                path="/editcollar/:id"
                element={<PrivateRoute>
                    <EditCollar />
                </PrivateRoute>}
                />
            </Routes>
        </Router>

        </>
    );
}

export default App;
